import { cast, ExtractRow, sql } from "@evolu/react";
import { evolu } from "../../../../lib/DB";
import { TaskID } from "./TaskID";
import { Task } from "./Task";

export function findAllTasks() {
    return evolu.createQuery(
        (db) => db
            .selectFrom("task")
            .leftJoin("person", "person.id", "task.person_id")
            .leftJoin("property", "property.id", "task.property_id")
            .selectAll(["task"])
            .select([sql<string>`concat(person.firstname, ' ', person.lastname)`.as('person'), "property.title as property"])
            .where("task.isDeleted", "is not", cast(true))
    );
}
export type findAllTasks = ExtractRow<ReturnType<typeof findAllTasks>>;

export function getTaskByID(id: TaskID) {
    return evolu.createQuery(
        (db) => db
            .selectFrom("task")
            .selectAll()
            .where("task.id", "=", id)
    );
}

export function createTask(data: Task) {
    evolu.create("task", data);
}

export function updateTask(data: Task) {
    evolu.update("task", data);
}

export function deleteTask(id: TaskID) {
    evolu.update("task", {id, isDeleted: true});
}