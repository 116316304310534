import { Schema } from "@effect/schema";
import { PersonPropertyID } from "./PersonPropertyID";
import { PersonID } from "../person/db/PersonID";
import { PropertyID } from "../property/db/PropertyID";
import { table } from "@evolu/react";

export const PersonProperty = Schema.Struct({
    id: PersonPropertyID,
    person_id: PersonID,
    property_id: PropertyID,
});
export type PersonProperty = typeof PersonProperty.Type;

export const PersonPropertyDBTable = table(PersonProperty.fields);
export type PersonPropertyDBTable = typeof PersonPropertyDBTable.Type;