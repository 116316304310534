import { createFileRoute } from '@tanstack/react-router'
import TaskForm from '../../features/task/TaskForm';

export const Route = createFileRoute('/tasks/create')({
  component: CreateTaskRouteComponent
})

function CreateTaskRouteComponent() {
  return (
    <>
      <TaskForm defaultValues={ undefined }/>
    </>
  );
}