import { Title, Text, Button, Container, Group } from '@mantine/core';
import { Link } from '@tanstack/react-router';

export default function NotFound() {
  return (
    <Container>
      <Text size={"xl"} ta={"center"}>404</Text>
      <Title ta={"center"}>Nothing to see here</Title>
      <Text size="lg" ta="center">
      Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.
      </Text>
      <Group justify="center">
        <Button component={Link} to={"/"} variant="blue" size="md">
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}