import { createRootRoute } from "@tanstack/react-router";
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import AppLayout from "../components/Layout/AppLayout";
import NotFound from "../components/Error/NotFound";

export const Route = createRootRoute({
    component: RootRouteComponent,
    notFoundComponent: NotFound
});

function RootRouteComponent() {
    return (
        <>
            <AppLayout />
            <TanStackRouterDevtools />
        </>
    );
}