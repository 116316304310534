import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@tanstack/react-form";
import { Property } from "./db/Property";
import { createProperty, updateProperty } from "./db/PropertyQueries";
import { useNavigate } from "@tanstack/react-router";

interface PropertyFormProps {
    defaultValues: Property | undefined,
}

export default function PropertyForm({ defaultValues }: PropertyFormProps) {
    const navigate = useNavigate();
    const {Field, Subscribe, handleSubmit } = useForm<Property | undefined>({
        defaultValues,
        onSubmit: async ({value}) => {
            if(value) {
                defaultValues?.id ? updateProperty(value) : createProperty(value);

                navigate({to: "/properties"});
            }
        }
    });

    return (
        <Box>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <Field 
                    name="title"
                    validators={{
                        onChange: ({value}) => 
                            !value
                                ? 'A title is required'
                                : value.length < 3
                                    ? 'Title must be at least 3 characters'
                                    : undefined,
                        
                    }}
                    children={({state, handleChange}) => {
                        return (
                            <>
                                <TextInput
                                    label={"Title"}
                                    value={state.value}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.value);
                                    }}
                                    error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                />
                            </>
                        );
                    }}
                />
                <Subscribe
                    selector={(state) => [state.canSubmit, state.isSubmitting]}
                    children={([canSubmit, isSubmitting]) => (
                        <Button mt={4}  disabled={!canSubmit} loading={isSubmitting} type='submit'>
                            Submit
                        </Button>
                    )}
                />
            </form>
        </Box>
    );
}