import { createFileRoute } from '@tanstack/react-router'
import PropertyTable from '../../features/property/PropertyTable';

export const Route = createFileRoute('/properties/')({
  component: PropertiesIndexRouteComponent
})

function PropertiesIndexRouteComponent() {
    return (
        <>
            <PropertyTable />
        </>
    );
}