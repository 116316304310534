import { cast, ExtractRow } from "@evolu/react";
import { evolu } from "../../../../lib/DB";
import { PropertyID } from "./PropertyID";
import { Property } from "./Property";
import { PersonID } from "../../person/db/PersonID";

interface findAllPropertiesProps {
    person_id?: PersonID,
}

export function findAllProperties(props?: findAllPropertiesProps) {
    return evolu.createQuery(
        (db) => {
            let q = db
            .selectFrom("property")
            .selectAll(["property"])

            if(props && props.person_id){
                q = q
                    .innerJoin("person_property", "person_property.property_id", "property.id")
                    .where("person_property.person_id", "=", props.person_id);
            }

            q = q.where("property.isDeleted", "is not", cast(true));

            return q;
        }
    );
}
export type findAllProperties = ExtractRow<ReturnType<typeof findAllProperties>>

export function getPropertyByID(id: PropertyID){
    return evolu.createQuery(
        (db) => db
            .selectFrom("property")
            .selectAll()
            .where("id","=", id)
    );
}

export function createProperty(data: Property){
    evolu.create("property", data);
}

export function updateProperty(data: Property) {
    evolu.update("property", data);
}

export function deleteProperty(id: PropertyID) {
    evolu.update("property", {id, isDeleted: true});
}