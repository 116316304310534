import { useForm } from "@tanstack/react-form";
import { Person } from "./db/Person";
import { useNavigate } from "@tanstack/react-router";
import { Box, Button, TextInput } from "@mantine/core";
import { createPerson, updatePerson } from "./db/PersonQueries";

interface PersonFormProps {
    defaultValues: Person | undefined,
}
export default function PersonForm({ defaultValues }: PersonFormProps) {
    const navigate = useNavigate();
    const { Field, Subscribe, handleSubmit } = useForm<Person | undefined>({
        defaultValues,
        onSubmit: async ({value}) => {
            if(value) {
                defaultValues?.id ? updatePerson(value) : createPerson(value);

                navigate({to: "/people"});
            }
        }
    });
    return (
        <Box>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <Field 
                    name="firstname"
                    children={({state, handleChange}) => {
                        return (
                            <>
                                <TextInput 
                                    label={"First Name"}
                                    value={state.value}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.value);
                                    }}
                                    error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="lastname"
                    children={({state, handleChange}) => {
                        return (
                            <>
                                <TextInput 
                                    label={"Last Name"}
                                    value={state.value}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.value);
                                    }}
                                    error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="phone"
                    children={({state, handleChange}) => {
                        return (
                            <>
                                <TextInput 
                                    label={"Phone"}
                                    value={state.value}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.value);
                                    }}
                                    error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="email"
                    children={({state, handleChange}) => {
                        return (
                            <>
                                <TextInput 
                                    label={"Email"}
                                    value={state.value}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.value);
                                    }}
                                    error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                />
                            </>
                        );
                    }}
                />
                <Subscribe
                    selector={(state) => [state.canSubmit, state.isSubmitting]}
                    children={([canSubmit, isSubmitting]) => (
                        <Button mt={4}  disabled={!canSubmit} loading={isSubmitting} type='submit'>
                            Submit
                        </Button>
                    )}
                />
            </form>
        </Box>
    );
}