import { AppShell, Box, Burger, Group, NavLink, ScrollArea } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { Link, Outlet } from "@tanstack/react-router";
import reactLogo from "../../assets/react.svg"
import { Suspense } from "react";
import { FaGauge, FaHouseChimney, FaPeopleRoof, FaRegCalendarCheck } from "react-icons/fa6";

export default function AppLayout() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened},
      }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
          <img src={reactLogo} className="logo react" alt="React logo" />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <AppShell.Section>
          
        </AppShell.Section>
        <AppShell.Section grow component={ScrollArea}>
          <NavLink component={Link} to="/" label={"Home"} leftSection={<FaGauge />}/>
          <NavLink component={Link} to="/properties" label={"Properties"} leftSection={<FaHouseChimney />}/>
          <NavLink component={Link} to="/people" label={"People"} leftSection={<FaPeopleRoof />}/>
          <NavLink component={Link} to="/tasks" label={"Tasks"} leftSection={<FaRegCalendarCheck />}/>
        </AppShell.Section>
        <AppShell.Section>
          Navbar footer – always at the bottom
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main style={{backgroundColor: 'var(--mantine-color-gray-0)',}}>
        <Suspense>
          <Box bg={"white"} p={"md"} bd={"1px solid gray.3"} style={{borderRadius: `var(--mantine-radius-sm)`}}>
            <Outlet />
          </Box>
        </Suspense>
      </AppShell.Main>
    </AppShell>
  );
}