import { createFileRoute } from '@tanstack/react-router'
import PersonTable from '../../features/person/PersonTable';

export const Route = createFileRoute('/people/')({
  component: PeopleIndexComponent
})

function PeopleIndexComponent() {
  return (
    <>
      <PersonTable />
    </>
  );
}