import React from 'react'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import PWABadge from './components/PWABadge/PWABadge.tsx'
import { MantineProvider } from '@mantine/core'
import { theme } from './theme.ts';
import "./styles.css"

// Import the generated route tree
import { routeTree } from './routeTree.gen'
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { EvoluProvider } from '@evolu/react';
import { evolu } from '../lib/DB.ts';

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <EvoluProvider value={evolu}>
        <RouterProvider router={router} />
      </EvoluProvider>
      <PWABadge />
    </MantineProvider>
  </React.StrictMode>,
)
