import { createFileRoute } from '@tanstack/react-router'
import { PersonID } from '../../features/person/db/PersonID';
import { Schema } from '@effect/schema';
import TaskForm from '../../features/task/TaskForm';
import { Task, TaskSubject } from '../../features/task/db/Task';

export const Route = createFileRoute('/people/$personID/task/create/subject/$subjectType')({
  component: CreateTaskFromPersonRouteComponent
})

function CreateTaskFromPersonRouteComponent() {
  const { personID, subjectType } = Route.useParams();
  
  return (
    <>
      <TaskForm 
        defaultValues={{
          person_id: Schema.decodeSync(PersonID)(personID),
          subject: TaskSubject.enums[subjectType as TaskSubject]
        } as Task}
      />
    </>
  );
}