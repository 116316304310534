import { createFileRoute } from '@tanstack/react-router'
import TaskForm from '../../features/task/TaskForm';
import { Schema } from '@effect/schema';
import { PropertyID } from '../../features/property/db/PropertyID';
import { Task, TaskSubject } from '../../features/task/db/Task';

export const Route = createFileRoute('/properties/$propertyID/task/create/subject/$subjectType')({
  component: CreateTaskFromPropertyRouteComponent
})

function CreateTaskFromPropertyRouteComponent() {
  const { propertyID, subjectType } = Route.useParams();
  
  return (
    <>
      <TaskForm 
        defaultValues={{
          property_id: Schema.decodeSync(PropertyID)(propertyID),
          subject: TaskSubject.enums[subjectType as TaskSubject]
        } as Task}
      />
    </>
  );
}