import { useQuery } from "@evolu/react";
import { deletePerson, findAllPeople } from "./db/PersonQueries";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ActionIcon, Button, Group, Menu, Table } from "@mantine/core";
import { FaEnvelope, FaHandshake, FaIdCard, FaPencil, FaPhone, FaRegCalendarPlus, FaTrashCan } from "react-icons/fa6";
import { Link } from "@tanstack/react-router";

const columnHelper = createColumnHelper<findAllPeople>();

const columns = [
    columnHelper.accessor("firstname", {
        header: "First Name",
        cell: info => info.getValue(),
    }),
    columnHelper.accessor("lastname", {
        header: "Last Name",
        cell: info => info.getValue(),
    }),
    columnHelper.accessor("phone", {
        header: "Phone",
        cell: info => info.getValue(),
    }),
    columnHelper.accessor("email", {
        header: () => "Email",
        cell: info => info.getValue(),
    }),
    columnHelper.display({
        id: "actions",
        header: () => "Actions",
        cell(props) {
            return (
                <Group gap={4}>
                <Menu position="bottom-start">
                    <Menu.Target>
                        <ActionIcon color="orange" aria-label={"Detail"}>
                            <FaRegCalendarPlus />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item 
                            component={Link} 
                            to="/people/$personID/task/create/subject/$subjectType" 
                            params={{
                                personID: props.row.original.id,
                                subjectType: "Email",
                            }}
                            leftSection={<FaEnvelope />}
                        >
                            Email
                        </Menu.Item>
                        <Menu.Item 
                            component={Link} 
                            to="/people/$personID/task/create/subject/$subjectType" 
                            params={{
                                personID: props.row.original.id,
                                subjectType: "Call",
                            }}
                            leftSection={<FaPhone />}
                        >
                            Call
                        </Menu.Item>
                        <Menu.Item 
                            component={Link} 
                            to="/people/$personID/task/create/subject/$subjectType" 
                            params={{
                                personID: props.row.original.id,
                                subjectType: "Meeting",
                            }}
                            leftSection={<FaHandshake />}
                        >
                            Meeting
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                    <ActionIcon color="blue" aria-label={"Detail"}>
                        <FaIdCard />
                    </ActionIcon>
                    <ActionIcon color="green" aria-label={"Edit"} component={Link} to={"/people/$personID/edit"} params={{personID: props.row.original.id, }}>
                        <FaPencil />
                    </ActionIcon>
                    <ActionIcon color="red" aria-label={"Delete"} onClick={() => { 
                            deletePerson(props.row.original.id);
                            props.table.reset();
                        }}>
                        <FaTrashCan />
                    </ActionIcon>
                </Group>
            );
        },
    }),
];

export default function PersonTable() {
    const { rows } = useQuery(findAllPeople());
    const table = useReactTable({
        columns,
        data: rows as [findAllPeople],
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <>
            <Group justify="flex-end">
                <Button component={Link} to={"/people/create"}>New Person</Button>
            </Group>
            <Table>
                <Table.Thead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Table.Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Table.Th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                        </Table.Th>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Thead>
                <Table.Tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Table.Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Table.Td
                                        data-title={cell.column.id}
                                        key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Table.Td>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Tbody>
            </Table>
        </>
    );
}