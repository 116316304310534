import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/properties')({
  component: PropertiesRouteComponent
})

function PropertiesRouteComponent() {
  return (
    <>
      <Outlet />
    </>
  );
}