import { createFileRoute } from '@tanstack/react-router'
import PersonForm from '../../features/person/PersonForm';

export const Route = createFileRoute('/people/create')({
  component: CreatePersonRouteComponent
})

function CreatePersonRouteComponent() {
  return (
    <>
      <PersonForm defaultValues={ undefined } />
    </>
  );
}