import { Button } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router'
import { evolu } from '../../lib/DB';
import { findAllProperties } from '../features/property/db/PropertyQueries';
import { Schema } from '@effect/schema';
import { PersonID } from '../features/person/db/PersonID';


export const Route = createFileRoute('/')({
  component: IndexRouteComponent
})

function IndexRouteComponent() {
  return (
    <>
      <Button
        color='red'
        onClick={() => {
          evolu.resetOwner();
        }}>
        Reset Evolu Owner
      </Button>
      <Button
        color='green'
        onClick={() => {
          evolu.loadQuery(findAllProperties({person_id: Schema.decodeSync(PersonID)("KkgtgN-_SLzX-OcDVmEba")}))
          .then(({ rows }) => { console.log(rows.map(({id, title}) => ({label: title ? title : "", value: id.toString()})))})
          .catch((error) => console.log(error));
        }}
      >
        Test it !
      </Button>
    </>
  );
}