import { cast, ExtractRow } from "@evolu/react";
import { evolu } from "../../../../lib/DB"
import { PersonID } from "./PersonID";
import { Person } from "./Person";
import { PropertyID } from "../../property/db/PropertyID";

interface findAllPeopleProps {
    property_id?: PropertyID,
}

export function findAllPeople(props?: findAllPeopleProps) {
    return evolu.createQuery(
        (db) => {
            let q = db
            .selectFrom("person")
            .selectAll(["person"])

            if(props && props.property_id){
                q = q
                    .innerJoin("person_property", "person_property.person_id", "person.id")
                    .where("person_property.property_id", "=", props.property_id);
            }

            q = q.where("person.isDeleted", "is not", cast(true))

            return q;
        }
    );
}
export type findAllPeople = ExtractRow<ReturnType<typeof findAllPeople>>

export function getPersonByID(id: PersonID) {
    return evolu.createQuery(
        (db) => db
            .selectFrom("person")
            .selectAll()
            .where("person.id", "=", id)
    );
}

export function createPerson(data: Person) {
    evolu.create("person", data);
}

export function updatePerson(data: Person) {
    evolu.update("person", data);
}

export function deletePerson(id: PersonID) {
    evolu.update("person", {id, isDeleted: true});
}