import { Schema } from "@effect/schema";
import { TaskID } from "./TaskID";
import { PersonID } from "../../person/db/PersonID";
import { SqliteDate, table } from "@evolu/react";
import { PropertyID } from "../../property/db/PropertyID";

enum _TaskSubject {
    Email = "Email",
    Call = "Call",
    Meeting = "Meeting",
}
export const TaskSubject = Schema.Enums(_TaskSubject);
export type TaskSubject = keyof typeof TaskSubject.enums;

enum _TaskStatus {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Closed = "Closed",
}
export const TaskStatus = Schema.Enums(_TaskStatus);
export type TaskStatus = keyof typeof TaskStatus.enums;

enum _TaskPriority {
    High = "High",
    Medium = "Medium",
    Low = "Low",
}
export const TaskPriority = Schema.Enums(_TaskPriority);
export type TaskPriority = keyof typeof TaskPriority.enums;

export const Task = Schema.Struct({
    id: TaskID,
    date: SqliteDate,
    subject: TaskSubject,
    status: TaskStatus,
    priority: TaskPriority,
    property_id: PropertyID,
    person_id: PersonID,
});
export type Task = typeof Task.Type;

export const TaskDBTable = table(Task.fields);
export type TaskDBTable = typeof TaskDBTable.Type;