import { useForm } from "@tanstack/react-form";
import { Task, TaskPriority, TaskStatus, TaskSubject } from "./db/Task";
import { Box, Button, Select } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { SqliteDate, useQuery } from "@evolu/react";
import { findAllProperties } from "../property/db/PropertyQueries";
import { Schema } from "@effect/schema";
import { PropertyID } from "../property/db/PropertyID";
import { PersonID } from "../person/db/PersonID";
import { findAllPeople } from "../person/db/PersonQueries";
import { createTask, updateTask } from "./db/TaskQueries";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { evolu } from "../../../lib/DB";

const TaskNull = Schema.NullOr(Task);
type TaskNull = typeof TaskNull.Type;

interface TaskFormProps {
    defaultValues: TaskNull | undefined,
}

export default function TaskForm({ defaultValues }: TaskFormProps) {
    const navigate = useNavigate();
    const { rows: propertiesRows } = useQuery(findAllProperties(defaultValues?.person_id ? {person_id: defaultValues.person_id} : {}));
    const { rows: peopleRows } = useQuery(findAllPeople(defaultValues?.property_id ? {property_id: defaultValues.property_id} : {}));

    const [properties, setProperties] = useState(propertiesRows.map(({id, title}) => ({label: title ? title : "", value: id.toString()})));
    const [people, setPeople] = useState(peopleRows.map(({id, firstname, lastname}) => ({label: firstname && lastname ? firstname.concat(" ".concat(lastname)) : "", value: id.toString()})));

    const { Field, Subscribe, handleSubmit } = useForm<TaskNull | undefined>({
        defaultValues,
        onSubmit: async ({value}) => {
            if(value) {
                defaultValues?.id ? updateTask(value) : createTask(value);

                navigate({ to: "/tasks" });
            }
        },
    }); 


    return (
        <Box>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <Field 
                    name="property_id"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                <Select 
                                    id={name}
                                    key={name}
                                    clearable
                                    label="Property"
                                    data={properties}
                                    defaultValue={state.value}
                                    value={state.value}
                                    disabled={!!defaultValues?.property_id || !!state.value}
                                    onChange={(value) => {
                                        evolu.loadQuery(findAllPeople(value ? {property_id: Schema.decodeSync(PropertyID)(value)} : {}))
                                        .then(({ rows }) => {
                                            const data = rows.map(({id, firstname, lastname}) => ({label: firstname && lastname ? firstname.concat(" ".concat(lastname)) : "", value: id.toString()}));
                                            setPeople(data);
                                        })
                                        .catch((error) => console.log(error))
                                        .finally(() => {
                                            //setFieldValue("person_id", null);
                                            handleChange(value ? Schema.decodeSync(PropertyID)(value) : null);
                                        });
                                    }}
                                    
                                    onClear={() => {
                                        //handleChange(null);
                                    }}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="person_id"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                <Select 
                                    id={name}
                                    key={name}
                                    label="Person"
                                    clearable
                                    data={people}
                                    defaultValue={state.value}
                                    value={state.value}
                                    disabled={!!defaultValues?.person_id || !!state.value}
                                    onChange={(value) => {
                                        evolu.loadQuery(findAllProperties(value ? {person_id: Schema.decodeSync(PersonID)(value)} : {}))
                                        .then(({ rows }) => {
                                            const data = rows.map(({id, title}) => ({label: title ? title : "", value: id.toString()}));
                                            setProperties(data);
                                        })
                                        .catch((error) => console.log(error))
                                        .finally(() => {
                                            //setFieldValue("property_id", null);
                                            handleChange(value ? Schema.decodeSync(PersonID)(value) : null);
                                        });
                                    }}
                                    onClear={() => {
                                        //handleChange(null);
                                    }}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="date"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                    <DateTimePicker
                                        id={name}
                                        key={name}
                                        label="Date"
                                        valueFormat="DD.MM.YYYY hh.mm"
                                        defaultValue={
                                            state.value ? new Date(state.value) : null
                                        }
                                        onChange={(value) => {
                                            if(value)
                                                handleChange(value.toISOString() as SqliteDate);
                                        }}
                                        error={state.meta.isTouched && state.meta.errors.length ? state.meta.errors.join(', ') : false}
                                    />
                            </>
                        );
                    }}
                />
                <Field 
                    name="subject"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                <Select 
                                    id={name}
                                    key={name}
                                    label="Subject"
                                    data={Object.keys(TaskSubject.enums).map((key) => ({label: key, value: TaskSubject.enums[key as TaskSubject]}))}
                                    defaultValue={state.value}
                                    value={state.value}
                                    onChange={(value) => handleChange(TaskSubject.enums[value as TaskSubject])}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="status"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                <Select 
                                    id={name}
                                    key={name}
                                    label="Status"
                                    data={Object.keys(TaskStatus.enums).map((key) => ({label: key, value: TaskStatus.enums[key as TaskStatus]}))}
                                    defaultValue={state.value}
                                    value={state.value}
                                    onChange={(value) => handleChange(TaskStatus.enums[value as TaskStatus])}
                                />
                            </>
                        );
                    }}
                />
                <Field 
                    name="priority"
                    children={({name, state, handleChange}) => {
                        return (
                            <>
                                <Select 
                                    id={name}
                                    key={name}
                                    label="Priority"
                                    data={Object.keys(TaskPriority.enums).map((key) => ({label: key, value: TaskPriority.enums[key as TaskPriority]}))}
                                    defaultValue={state.value}
                                    value={state.value}
                                    onChange={(value) => handleChange(TaskPriority.enums[value as TaskPriority])}
                                />
                            </>
                        );
                    }}
                />
                <Subscribe
                    selector={(state) => [state.canSubmit, state.isSubmitting]}
                    children={([canSubmit, isSubmitting]) => (
                        <Button mt={4}  disabled={!canSubmit} loading={isSubmitting} type='submit'>
                            Submit
                        </Button>
                    )}
                />
            </form>
        </Box>
    );
}