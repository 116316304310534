import { createFileRoute } from '@tanstack/react-router'
import PropertyForm from '../../features/property/PropertyForm';
import { useQuery } from '@evolu/react';
import { getPropertyByID } from '../../features/property/db/PropertyQueries';
import { Schema } from '@effect/schema';
import { PropertyID } from '../../features/property/db/PropertyID';
import { Property } from '../../features/property/db/Property';

export const Route = createFileRoute('/properties/$propertyID/edit')({
  component: EditPropertyRouteComponent
})

function EditPropertyRouteComponent() {
  const { propertyID } = Route.useParams();
  const { row } = useQuery(getPropertyByID(Schema.decodeSync(PropertyID)(propertyID)));
  return (
    <>
      <PropertyForm defaultValues={ row as Property }/>
    </>
  );
}