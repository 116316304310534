import { Schema } from "@effect/schema";
import { PropertyID } from "./PropertyID";
import { table } from "@evolu/react";

export const Property = Schema.Struct({
    id: PropertyID,
    title: Schema.String,
});
export type Property = typeof Property.Type;

export const PropertyDBTable = table(Property.fields);
export type PropertyDBTable = typeof PropertyDBTable.Type;