import { createFileRoute } from '@tanstack/react-router'
import TaskForm from '../../features/task/TaskForm';
import { useQuery } from '@evolu/react';
import { getTaskByID } from '../../features/task/db/TaskQueries';
import { Schema } from '@effect/schema';
import { TaskID } from '../../features/task/db/TaskID';
import { Task } from '../../features/task/db/Task';

export const Route = createFileRoute('/tasks/$taskID/edit')({
  component: EditTaskRouteComponent
})

function EditTaskRouteComponent() {
  const { taskID } = Route.useParams();
  const { row } = useQuery(getTaskByID(Schema.decodeSync(TaskID)(taskID)));
  return (
    <>
      <TaskForm defaultValues={ row as Task } />
    </>
  );
}