import { createFileRoute } from '@tanstack/react-router'
import PropertyForm from '../../features/property/PropertyForm';

export const Route = createFileRoute('/properties/create')({
  component: CreatePropertyRouteComponent
})

function CreatePropertyRouteComponent() {
  return (
    <>
      <PropertyForm defaultValues={ undefined }/>
    </>
  );
}