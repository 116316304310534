import { Schema } from "@effect/schema";
import { PersonID } from "./PersonID";
import { table } from "@evolu/react";

export const Person = Schema.Struct({
    id: PersonID,
    firstname: Schema.String,
    lastname: Schema.String,
    phone: Schema.String,
    email: Schema.String,
});
export type Person = typeof Person.Type;

export const PersonDBTable = table(Person.fields);
export type PersonDBTable = typeof PersonDBTable.Type;