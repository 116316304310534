import { cast, createEvolu, database } from "@evolu/react";
import { PropertyDBTable } from "../src/features/property/db/Property"
import { PersonDBTable } from "../src/features/person/db/Person";
import { TaskDBTable, TaskPriority, TaskStatus, TaskSubject } from "../src/features/task/db/Task";
import { PersonPropertyDBTable } from "../src/features/common/PersonProperty";

const Database = database({
    person: PersonDBTable,
    property: PropertyDBTable,
    person_property: PersonPropertyDBTable,
    task: TaskDBTable,
});
export type Database = typeof Database.Type;

export const evolu = createEvolu(Database, {
    initialData(evolu) {
        const person = evolu.create("person", {
            firstname: "Anıl",
            lastname: "Kan",
            email: "yelkenci2004@gmail.com",
            phone: "5059154758",
        });
        
        const property = evolu.create("property", {
            title: "Initial Property 01"
        });

        evolu.create("task", {
            date: cast(new Date()),
            property_id: property.id,
            person_id: person.id,
            priority: TaskPriority.enums.High,
            status: TaskStatus.enums.InProgress,
            subject: TaskSubject.enums.Call
        });

        evolu.create("person_property", {
            person_id: person.id,
            property_id: property.id,
        });
    }
});