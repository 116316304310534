import { createFileRoute } from '@tanstack/react-router'
import TaskTable from '../../features/task/TaskTable';

export const Route = createFileRoute('/tasks/')({
  component: TaskIndexRouteComponent
})

function TaskIndexRouteComponent() {
  return (
    <>
      <TaskTable />
    </>
  );
}