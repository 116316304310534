import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { deleteTask, findAllTasks } from "./db/TaskQueries";
import { ActionIcon, Box, Button, Group, Table } from "@mantine/core";
import { FaIdCard, FaPencil, FaTrashCan } from "react-icons/fa6";
import { useQuery } from "@evolu/react";
import { Link } from "@tanstack/react-router";
import { useMediaQuery } from "@mantine/hooks";
const columnHelper = createColumnHelper<findAllTasks>();

const columns = [
    columnHelper.accessor("person", {
        header: () => "Person",
        cell: info => info.getValue()
    }),
    columnHelper.accessor("property", {
        header: () => "Property",
        cell: info => info.getValue()
    }),
    columnHelper.accessor("date", {
        header: () => "Date",
        cell: info => info.getValue()
    }),
    columnHelper.accessor("subject", {
        header: () => "Subject",
        cell: info => info.getValue()
    }),
    columnHelper.accessor("status", {
        header: () => "Status",
        cell: info => info.getValue()
    }),
    columnHelper.accessor("priority", {
        header: () => "Priority",
        cell: info => info.getValue()
    }),
    columnHelper.display({
        id: "actions",
        header: () => "Actions",
        cell(props) {
            return (
                <Group gap={4}>
                    <ActionIcon color="blue" aria-label={"Detail"}>
                        <FaIdCard />
                    </ActionIcon>
                    <ActionIcon color="green" aria-label={"Edit"} component={Link} to={"/tasks/$taskID/edit"} params={{taskID: props.row.original.id, }}>
                        <FaPencil />
                    </ActionIcon>
                    <ActionIcon color="red" aria-label={"Delete"} onClick={() => { 
                            deleteTask(props.row.original.id);
                            props.table.reset();
                        }}>
                        <FaTrashCan />
                    </ActionIcon>
                </Group>
            );
        },
    }),
];

export default function TaskTable() {
    useMediaQuery("");
    const { rows } = useQuery(findAllTasks());
    const table = useReactTable({
        columns,
        data: rows as [findAllTasks],
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <Box style={{ overflowX: "auto"}}>
            <Group justify="flex-end">
                <Button component={Link} to={"/tasks/create"} disabled={false}>New Task</Button>
            </Group>
            <Table>
                <Table.Thead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Table.Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Table.Th key={header.id}
                                        colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                        </Table.Th>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Thead>
                <Table.Tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Table.Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Table.Td
                                        data-title={cell.column.id}
                                        key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Table.Td>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Tbody>
            </Table>
        </Box>
    );
}