import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ActionIcon, Button, Group, Menu, Table } from "@mantine/core";
import { useQuery } from "@evolu/react";
import { deleteProperty, findAllProperties } from "./db/PropertyQueries";
import { FaEnvelope, FaHandshake, FaIdCard, FaPencil, FaPhone, FaRegCalendarPlus, FaTrashCan } from "react-icons/fa6";
import { Link } from "@tanstack/react-router";

const columnHelper = createColumnHelper<findAllProperties>();

const columns = [
    columnHelper.accessor("title", {
        header: () => "Title",
        cell: info => info.getValue()
    }),
    columnHelper.display({
        id: "actions",
        header: () => "Actions",
        cell(props) {
            return (
                <Group gap={4}>
                    <Menu position="bottom-start">
                        <Menu.Target>
                            <ActionIcon color="orange" aria-label={"Detail"}>
                                <FaRegCalendarPlus />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item 
                                component={Link} 
                                to="/properties/$propertyID/task/create/subject/$subjectType" 
                                params={{
                                    propertyID: props.row.original.id,
                                    subjectType: "Email",
                                }}
                                leftSection={<FaEnvelope />}
                            >
                                Email
                            </Menu.Item>
                            <Menu.Item 
                                component={Link} 
                                to="/properties/$propertyID/task/create/subject/$subjectType" 
                                params={{
                                    propertyID: props.row.original.id,
                                    subjectType: "Call",
                                }}
                                leftSection={<FaPhone />}
                            >
                                Call
                            </Menu.Item>
                            <Menu.Item 
                                component={Link} 
                                to="/properties/$propertyID/task/create/subject/$subjectType" 
                                params={{
                                    propertyID: props.row.original.id,
                                    subjectType: "Meeting",
                                }}
                                leftSection={<FaHandshake />}
                            >
                                Meeting
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                    <ActionIcon color="blue" aria-label={"Detail"}>
                        <FaIdCard />
                    </ActionIcon>
                    <ActionIcon color="green" aria-label={"Edit"} component={Link} to={"/properties/$propertyID/edit"} params={{propertyID: props.row.original.id, }}>
                        <FaPencil />
                    </ActionIcon>
                    <ActionIcon color="red" aria-label={"Delete"} onClick={() => { 
                            deleteProperty(props.row.original.id);
                            props.table.reset();
                        }}>
                        <FaTrashCan />
                    </ActionIcon>
                </Group>
            );
        },
    }),
];

export default function PropertyTable() {
    const { rows } = useQuery(findAllProperties());
    const table = useReactTable({
        columns,
        data: rows as [findAllProperties],
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <>
            <Group justify="flex-end">
                <Button component={Link} to={"/properties/create"}>New Property</Button>
            </Group>
            <Table>
                <Table.Thead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Table.Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Table.Th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                        </Table.Th>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Thead>
                <Table.Tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Table.Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Table.Td
                                        data-title={cell.column.id}
                                        key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Table.Td>
                                    );
                                })}
                            </Table.Tr>
                        );
                    })}
                </Table.Tbody>
            </Table>
        </>
    );
}