import { createFileRoute } from '@tanstack/react-router'
import PersonForm from '../../features/person/PersonForm';
import { getPersonByID } from '../../features/person/db/PersonQueries';
import { useQuery } from '@evolu/react';
import { Schema } from '@effect/schema';
import { PersonID } from '../../features/person/db/PersonID';
import { Person } from '../../features/person/db/Person';

export const Route = createFileRoute('/people/$personID/edit')({
  component: EditPersonRouteComponent
})

function EditPersonRouteComponent() {
  const { personID } = Route.useParams();
  const { row } = useQuery(getPersonByID(Schema.decodeSync(PersonID)(personID)));
  return (
    <>
      <PersonForm defaultValues={ row as Person } />
    </>
  );
}